<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="850px" scrollable persistent>
      <v-card>
        <v-card-title>{{ datapolicy.policy_header }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6" v-html='datapolicy.policy_detail'></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{ $t("admin.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "datapolicy"],
  data: function () {
    return {
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
  },
  methods: {
  },
};
</script>
<style></style>
